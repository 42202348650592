import { isValid } from '@/utils/helper/form-helper'

export default {
  methods: {
    // formdata의 value 값이 있을 경우 유효성 체크
    isValid(properties, label) {
      return isValid(properties, label);
    },
    getParamData(formData) {
      let enable = true;
      let params = {};
      let msg = '';
      formData.some(item=>{
        // 유효성 체크
        msg = this.isValid(item, item.label);
        if(msg) {
          enable = false;
          this.toast(msg);
          item.errorMsg = msg;
          return true;
        }
        if (item.field === "funnel") {
          params[item.field] = item.options.find(o => o.value === item.value).label;
          if (params[item.field] === '기타') {
            params[item.field] = item.etc;
          }
        } else {
          params[item.field] = item.value;
        }
      })
      return { 'enable': enable, 'params': params }
    }
  }
}